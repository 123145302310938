'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpMenu = void 0;
var $ = require("jquery");
var SpMenu = /** @class */ (function () {
    function SpMenu() {
        var open = 0;
        var sub_open = 0;
        var self = this;
        $('#menuTrig').click(function (event) {
            self.open = Number($(event.currentTarget).attr("data-open"));
            if (self.open == 0) {
                $(event.currentTarget).attr("data-open", 1);
                $(event.currentTarget).addClass('active');
                $('.side-menu__box').slideDown();
            }
            else {
                // console.log('test');
                $(event.currentTarget).attr("data-open", 0);
                $(event.currentTarget).removeClass('active');
                $('.side-menu__box').slideUp();
            }
            return false;
        });
        $('.trigMenu').click(function (event) {
            self.sub_open = Number($(event.currentTarget).attr("data-open"));
            $(event.currentTarget).next('li').slideToggle();
            if (self.sub_open == 0) {
                $(event.currentTarget).attr("data-open", 1);
                $(event.currentTarget).addClass('slide-open');
                $(event.currentTarget).removeClass('slide-close');
            }
            else {
                $(event.currentTarget).attr("data-open", 0);
                $(event.currentTarget).addClass('slide-close');
                $(event.currentTarget).removeClass('slide-open');
            }
        });
    }
    return SpMenu;
}());
exports.SpMenu = SpMenu;
exports.default = SpMenu;
