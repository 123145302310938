'use strict';
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var Func = /** @class */ (function () {
    function Func() {
    }
    Func.isCurrentPageAnchor = function (href) {
        var url = location.href;
        // console.log("url", url);
        var host = location.host;
        // console.log("host", host);
        var protocol = location.protocol;
        // console.log("protocol", protocol);
        var uri = url.replace(protocol + '//' + host, '');
        // console.log("uri", uri);
        // const anchor = href.replace(uri, '');
        // console.log("anchor", anchor);
        var currentPath = uri.split('#');
        var hrefPath = href.split('#');
        // console.log("currentPath", currentPath);
        // console.log("hrefPath", hrefPath);
        return currentPath[0] === hrefPath[0];
    };
    Func.getOffsetTop = function (e) {
        var rect = e.getBoundingClientRect();
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop;
    };
    Func.sum = function (numbers) {
        var sum = 0;
        numbers.forEach(function (num) {
            sum += num;
        });
        return sum;
    };
    Func.getExtention = function (filename) {
        var types = filename.split('.');
        return types[types.length - 1].toLowerCase();
    };
    Func.numberFormat = function (number) {
        return number.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g, '$1,');
    };
    Func.formatDecimalToText = function (number) {
        var text = '';
        if (number.toString().indexOf('.') != 0) {
            var a = number.toString().split('.');
            if (a[1] == undefined) {
                a[1] = "0";
            }
            text = '<span class="decimal-parent">' + a[0] + '</span><span class="decimal-child">.' + a[1] + '</span>';
        }
        else {
            text = '<span class="decimal-parent">' + number + '</span><span class="decimal-child">.0</span>';
        }
        return text;
    };
    Func.unitFormat = function (number, unit) {
        if (unit === void 0) { unit = "B"; }
        var str = number;
        if (number > 1 * 1000 * 1000 * 1000) {
            str = Func.numberFormat((number / 1000 / 1000 / 1000).toFixed(2)) + "G";
        }
        else if (number > 1 * 1000 * 1000) {
            str = Func.numberFormat((number / 1000 / 1000).toFixed(2)) + "M";
        }
        else if (number > 1 * 1000) {
            str = Func.numberFormat((number / 1000).toFixed(2)) + "K";
        }
        return str + unit;
    };
    Func.inArray = function (needle, haystack) {
        var result = false;
        Object.keys(haystack).forEach(function (i) {
            if (needle == haystack[Number(i)]) {
                result = true;
            }
        });
        return result;
    };
    Func.dateFormat = function (date, format) {
        if (format === void 0) { format = 'Y-m-d H:i:s'; }
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDay();
        var hour = date.getHours();
        var minutes = date.getMinutes();
        var second = date.getSeconds();
        return format.replace('Y', year)
            .replace('m', Func.sprintf(month, 2))
            .replace('d', Func.sprintf(day, 2))
            .replace('n', month)
            .replace('j', day)
            .replace('H', hour)
            .replace('i', minutes)
            .replace('s', second);
    };
    /**
     * @param number
     * @param number
     * @return string
     */
    Func.sprintf = function (num, len) {
        if (String(num).length >= len) {
            return String(num);
        }
        else {
            var zeros = len - String(num).length;
            var str = "";
            for (var i = 0; i < zeros; i++) {
                str += "0";
            }
            return str + String(num);
        }
    };
    Func.smoothScroll = function (target, flagAnimation, speed) {
        if (speed === void 0) { speed = 500; }
        if (flagAnimation === true) {
            $("html, body").animate({ scrollTop: target }, speed, "swing");
        }
        else {
            speed = 0;
            $("html, body").animate({ scrollTop: target }, speed, "linear");
        }
        return false;
    };
    Func.getElementPosition = function (idName, minus) {
        if (minus === void 0) { minus = 0; }
        var pos = Number($(idName).offset().top);
        return pos - minus;
    };
    Func.number = function (val) {
        val = val.replace(/[^0-9]/g, '');
        if (isNaN(val)) {
            return 0;
        }
        else {
            return Number(val);
        }
    };
    Func.insertToTextarea = function (selector, word) {
        var textarea = document.querySelector(selector);
        var sentence = textarea.value;
        var len = sentence.length;
        var pos = textarea.selectionStart;
        var before = sentence.substr(0, pos);
        var after = sentence.substr(pos, len);
        sentence = before + word + after;
        textarea.value = sentence;
        var endPosition = pos + word.replace(/\r/g, '').length;
        textarea.setSelectionRange(endPosition, endPosition);
    };
    Func.isLarge = function (sizeId) {
        var result = false;
        switch (sizeId) {
            case 'B0':
            case 'B1':
            case 'B2':
            case 'B3':
            case 'A0':
            case 'A1':
            case 'A2':
                result = true;
                break;
        }
        return result;
    };
    Func.getSizeCategory = function (sizeId) {
        var category = '';
        if (sizeId == 'CD' || sizeId == 'NM') {
            category = 'card';
        }
        else {
            category = sizeId.substr(0, 1);
        }
        return category;
    };
    Func.getSizeType = function (sizeId) {
        var type = '';
        if (sizeId == 'CD' || sizeId == 'NM') {
            type = 'special';
        }
        else if (Func.isLarge(sizeId)) {
            type = 'large';
        }
        else {
            type = 'small';
        }
        return type;
    };
    Func.isSP = function () {
        var w = window.innerWidth;
        return w <= 480;
    };
    Func.isTab = function () {
        var w = window.innerWidth;
        return w >= 481 && w <= 999;
    };
    Func.isPC = function () {
        var w = window.innerWidth;
        return w >= 1000;
    };
    Func.getRandomInt = function (min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    };
    // テキストを分割して配列を取得
    Func.getWords = function (str) {
        var ary = [];
        str = str.replace(/　/g, ' ');
        if (str.indexOf(' ')) {
            ary = str.split(' ');
            ary = ary.filter(function (k) { return k; });
        }
        else if (str) {
            ary = [str];
        }
        return ary;
    };
    // Json データを取得
    Func.getJsonData = function (id) {
        var data = {};
        if ($('#' + id).length > 0) {
            var elem = document.getElementById(id);
            if (elem) {
                data = JSON.parse(elem.textContent);
            }
        }
        return data;
    };
    // 2つの配列から共通要素を取得
    Func.getArraysIntersect = function (ary1, ary2) {
        return __spreadArrays(ary1, ary2).filter(function (item) { return ary1.includes(item) && ary2.includes(item); });
    };
    // 重複を削除
    Func.getArrayUnique = function (_a) {
        var array = _a.slice(0);
        return array.filter(function (value, index, self) { return self.indexOf(value) === index; });
    };
    return Func;
}());
exports.default = Func;
