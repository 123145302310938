'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slick = void 0;
var $ = require("jquery");
require("slick-carousel");
var Slick = /** @class */ (function () {
    function Slick() {
        this.breakpoint = 480;
        $('.trigSlick').slick({
            autoplay: true,
            fade: true,
            autoplaySpeed: 4000,
            speed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: false,
                        dots: false,
                    }
                }
            ]
        });
    }
    return Slick;
}());
exports.Slick = Slick;
exports.default = Slick;
