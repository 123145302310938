'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmoothScroll = void 0;
var $ = require("jquery");
var func_1 = require("../config/func");
var SmoothScroll = /** @class */ (function () {
    function SmoothScroll() {
        var _this = this;
        this.scrollPlusHeight = 80;
        this.scrollSpeed = 550;
        this.scrollEasing = "swing";
        var self = this;
        $('a').click(function () {
            var href = $(this).attr("href");
            if (func_1.default.isCurrentPageAnchor(href)) {
                self.scrollToAnchor(href);
                $('#menuTrig').attr("data-open", 0).removeClass('active');
                $('.side-menu__box').slideUp();
                return false;
            }
            else if (self.isAnchor(href)) {
                self.scrollToAnchor(href);
                $('#menuTrig').attr("data-open", 0).removeClass('active');
                $('.side-menu__box').slideUp();
                return false;
            }
        });
        // URLがアンカーリンク付きだった時
        window.addEventListener('load', function (e) {
            var url = location.href;
            if (_this.hasAnchor(url)) {
                console.log(url);
                self.scrollToAnchor(url, 0);
                return false;
            }
        });
    }
    SmoothScroll.prototype.isAnchor = function (href) {
        return href.indexOf('#') === 0;
    };
    SmoothScroll.prototype.hasAnchor = function (href) {
        return href.indexOf('#') != -1;
    };
    SmoothScroll.prototype.getAnchorName = function (href) {
        var index = href.indexOf('#');
        return href.slice(index + 1);
    };
    SmoothScroll.prototype.scrollToAnchor = function (href, speed, scrollPlusHeight) {
        if (speed === void 0) { speed = this.scrollSpeed; }
        if (scrollPlusHeight === void 0) { scrollPlusHeight = this.scrollPlusHeight; }
        var anchor = this.getAnchorName(href);
        var offset = $('#' + anchor).offset().top;
        var position = offset;
        if (func_1.default.isSP() || func_1.default.isTab()) {
            position -= scrollPlusHeight;
        }
        $("html, body").animate({ scrollTop: position }, speed, this.scrollEasing);
    };
    return SmoothScroll;
}());
exports.SmoothScroll = SmoothScroll;
exports.default = SmoothScroll;
