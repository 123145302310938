'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopBtn = void 0;
var $ = require("jquery");
var TopBtn = /** @class */ (function () {
    function TopBtn() {
        this.topBtn = $('.top-btn');
        var self = this;
        $('.TopBtn').hover(function (event) {
            $(event.currentTarget).children('ul').stop().slideToggle();
        });
        // スクロール位置で表示
        //self.topBtn = $('.top-btn');
        $(window).scroll(function () {
            if ($(event.currentTarget).scrollTop() > 100) {
                self.topBtn.fadeIn(300, function () {
                    $('.top-btn').addClass('top-btn--show');
                });
            }
            else {
                $('.top-btn').removeClass('top-btn--show');
                self.topBtn.fadeOut();
            }
            // 最下層まで到達するとボタンが上に移動
            self.scrollHeight = $(document).height();
            self.scrollPosition = $(window).height() + $(event.currentTarget).scrollTop();
            $('.top-btn').css({ bottom: '20px' });
        });
    }
    return TopBtn;
}());
exports.TopBtn = TopBtn;
exports.default = TopBtn;
