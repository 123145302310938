'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navicon = void 0;
var $ = require("jquery");
var Navicon = /** @class */ (function () {
    function Navicon() {
        $('#trigNavicon').on('click', function () {
            var bulletNavi = $('#bulletNavi');
            bulletNavi.toggleClass('open');
            $('#bulletNavicon').toggleClass('open');
            // if(bulletNavi.hasClass('open')){
            //     $('#bulletNavicon').removeClass('fa-bars').addClass('fa-times');
            // }else{
            //     $('#bulletNavicon').removeClass('fa-times').addClass('fa-bars');
            // }
        });
        $('#bulletNavi').on('click', function () {
            $('#trigNavicon').removeClass('open');
            var bulletNavi = $('#bulletNavi');
            bulletNavi.removeClass('open');
            $('#bulletNavicon').removeClass('open');
            // $('#bulletNavicon').removeClass('fa-times').addClass('fa-bars');
        });
    }
    return Navicon;
}());
exports.Navicon = Navicon;
exports.default = Navicon;
