'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var navicon_1 = require("./class/navicon");
var smooth_scroll_1 = require("./class/smooth-scroll");
var slick_1 = require("./class/slick");
var news_1 = require("./class/news");
var accordion_1 = require("./class/accordion");
var sp_menu_1 = require("./class/sp_menu");
var top_btn_1 = require("./class/top_btn");
$(function () {
    new navicon_1.default;
    new smooth_scroll_1.default;
    new slick_1.default();
    new news_1.default();
    new accordion_1.default();
    new sp_menu_1.default();
    new top_btn_1.default();
});
