'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.News = void 0;
var $ = require("jquery");
var News = /** @class */ (function () {
    function News() {
        $('.trigToggleNewsBody').on('click', function () {
            $(this).next().slideToggle();
            // $('#bulletNews').toggleClass('open');
            // if(bulletNavi.hasClass('open')){
            //     $('#bulletNews').removeClass('fa-bars').addClass('fa-times');
            // }else{
            //     $('#bulletNews').removeClass('fa-times').addClass('fa-bars');
            // }
        });
    }
    return News;
}());
exports.News = News;
exports.default = News;
