'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
var $ = require("jquery");
var Accordion = /** @class */ (function () {
    function Accordion() {
        var self = this;
        $('.accordion').hover(function (event) {
            $(event.currentTarget).children('ul').stop().slideToggle();
        });
    }
    return Accordion;
}());
exports.Accordion = Accordion;
exports.default = Accordion;
